import React from 'react';
import differenceInYears from 'date-fns/differenceInYears';

const declension = ['год', 'года', 'лет'];

function plural(number, titles = declension) {
  const cases = [2, 0, 1, 1, 1, 2];
  const remainderOfDivisionBy100 = number % 100;
  const remainderOfDivisionBy10 = number % 10;
  return titles[
    remainderOfDivisionBy100 > 4 && remainderOfDivisionBy100 < 20
      ? 2
      : cases[remainderOfDivisionBy10 < 5 ? remainderOfDivisionBy10 : 5]
  ];
}

export function MyAge() {
  const years = differenceInYears(Date.now(), new Date(1999, 1, 25));
  return [years, plural(years)].join(' ');
}
